<template>
	<div>
		<div class=" text-right mb-5">
			<Button @click="()=>add()" type="primary">新建</Button>
		</div>
		<Table :columns="tableData.title" :data="tableData.list" >
			<template slot-scope="{row,index}" slot="img" >
				<div class=" p-2">
					<img :src="row.img" class=" w-48 h-24" />
				</div>
			</template>
			<template slot-scope="{row,index}" slot="operate">
				<Button @click="()=>edit(row)" size="small" type="primary" class=" mr-2">编辑</Button>
				<Poptip
					transfer
					confirm
					title="确定是否删除？"
					@on-ok="()=>remove(row)">
					<Button size="small" type="error">删除</Button>
				</Poptip>
			</template>
		</Table>
		<Card class=" text-right">
			<Page @on-change="(value)=>fetchData(value)"  :total="tableData.total" :pageSize="tableData.size"></Page>
		</Card>
		<Modal 
			:title="modelTitle"
			:closable="false"
			:maskClosable="false"  
			v-model="show">
			<Form
				class=" w-2/3"
				:label-width="100" 
				ref="form" 
				:model="form" 
				:rules="rulevalidate">
				<FormItem prop="name" label="别名">
					<Input v-model="form.name"/>
				</FormItem>
				<FormItem prop="state" label="状态">
					<i-Switch v-model="form.state"></i-Switch>
				</FormItem>
				<FormItem prop="type" label="公告类型">
					<Select v-model="form.type">
						<Option value="轮播图">轮播图</Option>
						<Option value="首页弹窗">首页弹窗</Option>
					</Select>
				</FormItem>
				<FormItem prop="img" label="公告图片:">
					<div style="display: flex;align-items: center;">
						<img 
							alt="无数据" 
							style="width: 200px;height: 100px;margin-right: 10px;" 
							:src="form.img" />
						<qiniuUploadBtn  
							:qiniu_config="qiniu_config"
							@set_url="(value)=>form.img=value"/>
					</div>
				</FormItem>
				<FormItem prop="toType" label="公告跳转类型">
					<Select v-model="form.toType">
						<Option value="静默">静默</Option>
						<Option value="网页">网页</Option>
						<Option value="小程序页">小程序页</Option>
						<Option value="外部小程序">外部小程序</Option>
					</Select>
				</FormItem>
				<FormItem v-if="form.toType=='外部小程序'" prop="urlAppid" label="urlAppid">
					<Input v-model="form.urlAppid"/>
				</FormItem>
				<FormItem v-if="form.toType!='静默'" prop="url" label="url">
					<Input v-model="form.url"/>
				</FormItem>
				<FormItem  prop="sortNum" label="公告排序">
					<Input type="number" v-model="form.sortNum"/>
				</FormItem>
			</Form>
			<div class=" flex justify-end" slot="footer">
				<Button @click="cancel">取消</Button>
				<Button @click="()=>submitAdd()" type="primary">确定</Button>
			</div>
		</Modal>
	</div>
</template>
<script>
	import {OperationChart,getupTK} from "@/request/api"
	import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue'
	export default{
		name:"popNotice",
		components: {
			qiniuUploadBtn
		},
		data(){
			return{
				modelTitle:"",
				show:false,
				tableData:"",
				form:{
					img:"",
					state:false,
				},
				rulevalidate:{
				},
				qiniu_config: {},
			}
		},
		created() {
			this.fetchData()
			this.to_getupTK()
		},
		methods:{
			/**
			 * 编辑 按钮
			 */
			async edit(value){
				await this.getRowData(value)
				this.show = true
				this.modelTitle = "编辑"
			},
			/**
			 * 删除 按钮
			 */
			async remove(value){
				const {id} = value
				await OperationChart({
					exeType:"删除数据",
					id
				})
				this.fetchData(this.tableData.page)
			},
			// 获取七牛云token
			async to_getupTK() {
				let {
					data: res_data
				} = await getupTK()
				this.qiniu_config = res_data.data
			},
			async fetchData(page=1){
				let res = await OperationChart({
					exeType:"获取列表",
					page
				})
				if(res.errcode!=0) return
				res.data.title.push({
					title:"操作",
					key:"operate",
					slot:"operate"
				})
				res.data.title.forEach((item,index)=>{
					if(item.key=='img'){
						item["slot"] = "img"
					}
				})
				this.tableData = res.data
			},
			/**
			 * 新增 按钮
			 */
			add(){
				this.show = true
				this.modelTitle = "新建"
			},
			/**
			 * 弹窗 新建提交
			 */
			async submitAdd(){
				let passdata = {
					exeData:this.form
				}
				if(this.modelTitle=="新建"){
					passdata.exeType = "新建数据"
				}
				if(this.modelTitle =="编辑"){
					passdata.exeType = "设置数据"
					passdata.id = this.form._id
				}
				let res = await OperationChart(passdata)
				if(res.errcode!=0) return
				this.show = false
				this.fetchData()
			},
			/**
			 * 弹窗取消 按钮
			 */
			cancel(){
				this.resetFeild("form")
				this.show = false
			},
			/**
			 * 重置表单验证
			 */
			resetFeild(name){
				this.$refs[name].resetFields();
			},
			/**
			 * 获取一行数据
			 */
			async getRowData(value){
				const {id} = value
				let res = await OperationChart({
					exeType:"获取数据",
					id
				})
				if(res.errcode!=0) return
				this.form = res.data
			}
		}
	}
</script>
