<template>
    <div>
        <div style="text-align: right">
            <Form inline @submit.native.prevent>
                <FormItem>
                    <Select v-model="currentNetSalesUser" style="text-align: left; width: 150px; margin-right: 10px">
                        <Option :key="index" v-for="(item, index) in NetSalesUserConfig" :value="item">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Input @on-enter="() => to_GetNetSalesUser()" :placeholder="modelPlaceholder" style="width: 300px" v-model="phone" />
                </FormItem>
                <FormItem>
                    <Button @click="() => to_GetNetSalesUser()" style="margin-left: 10px" type="primary">查询</Button>
                </FormItem>
                <FormItem>
                    <Button @click="() => table_dowload()" v-if="infos" style="margin-left: 10px">导出全部数据</Button>
                </FormItem>
            </Form>
        </div>
        <!-- 号码信息 -->
        <div style="margin-top: 10px">
            <Card class="flex">
                <div class="flex justify-between">
                    <pre class="pl-32">
			账户ID:{{NetSalesUserInfo.ID}}<span v-if="NetSalesUserInfo.realName">({{NetSalesUserInfo.realName}})</span>
			用户串码:{{NetSalesUserInfo.openid}}
			绑定号码:{{NetSalesUserInfo.phone}}                   <div style="display: inline-block;"><Poptip v-if="NetSalesUserInfo.phonelist" placement="right" trigger="hover" title="曾用号码" :content="NetSalesUserInfo.phonelist.join('/n')"><Button type="primary">曾用号码</Button></Poptip></div>
			创建时间:{{NetSalesUserInfo.creationTime}}
			当前影院:{{NetSalesUserInfo.mcode}}
			账户状态:  <Tag color="success" v-if="NetSalesUserInfo&&NetSalesUserInfo.state">正常</Tag><Tag color="error" v-if="NetSalesUserInfo&&!NetSalesUserInfo.state">冻结</Tag>
			</pre>
                    <div v-if="NetSalesUserInfo">
                        <Row>
                            <Col span="12">
                                <Select v-model="formOpreate.type" style="width: 150px">
                                    <Option value="解绑手机">解绑手机</Option>
                                    <Option value="冻结账号">冻结账号</Option>
                                    <Option value="解冻账号">解冻账号</Option>
                                    <Option value="清除绑定上限">清除绑定上限</Option>
                                    <Option value="赠送票券">赠送票券</Option>
                                </Select>
                            </Col>
                            <Col span="12">
                                <div class="pl-10">
                                    <Button @click="() => opreateBtn()">操作</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div style="overflow: hidden; border-radius: 500px; position: absolute; top: 20px; left: 50px; width: 100px; height: 100px">
                    <img
                        style="width: 100%; height: 100%"
                        :src="NetSalesUserInfo.imgURl ? NetSalesUserInfo.imgURl : require('../../../assets/images/no_img.png')"
                    />
                </div>
            </Card>
        </div>
        <!-- 详情查看 -->
        <div>
            <!-- 操作区域 -->
            <div>
                <Input v-show="false" @on-enter="() => to_GetOrderDatab()" autofocus v-model="keyword" style="width: 300px" placeholder="请输入订单号"></Input>
            </div>
            <div v-show="order.orderid" style="margin-top: 30px">
                <Card>
                    <!-- 电话号码 -->
                    <div v-if="order.mobilePhone">
                        <p style="border-left: 5px solid black; padding-left: 10px; margin: 10px 0">电话号码</p>
                        <div style="padding-left: 20px">
                            {{ order.mobilePhone }}
                        </div>
                    </div>
                    <!-- 订单信息 -->
                    <div>
                        <p style="border-left: 5px solid black; padding-left: 10px; margin: 10px 0">
                            订单信息
                            <!-- order -->
                        </p>
                        <div style="padding-left: 20px; position: relative">
                            订单号 {{ order.orderid }} <br />
                            订单名称
                            {{ order.orderName }}
                            <br />
                            订单描述 <span v-if="order.orderAbout" v-html="orderAbout(order.orderAbout)"></span>

                            <br />

                            订单状态 <Tag v-if="order.state" :color="state_color[order.state]">{{ state[order.state] }}</Tag>

                            <br />

                            订单创建时间 {{ $formatDate(order.createTime, 'yyyy年MM月dd日 HH:mm') }}
                            <br />
                            支付类型 <Tag color="orange">{{ order.pay }}</Tag>
                            <br />
                            订单价格 {{ order.orderPrice / 100 }}元
                            <br />
                            实付金额 {{ order.cash_fee / 100 }}元

                            <div style="width: 200px; height: 300px; position: absolute; top: -20px; right: 0">
                                <img style="width: 100%; height: 100%" :src="order.imgurl" />
                            </div>
                        </div>
                    </div>
                    <div v-if="order.qrCode != ''">
                        <p style="border-left: 5px solid black; padding-left: 10px; margin: 10px 0">取货码</p>
                        <div style="padding-left: 20px">
                            {{ order.qrCode }}
                        </div>
                    </div>
                    <div v-if="order.retime != ''">
                        <p style="border-left: 5px solid black; padding-left: 10px; margin: 10px 0">退款信息</p>
                        <div style="padding-left: 20px">
                            退款金额 <span style="color: red">{{ order.refund }}</span> 元
                            <br />
                            退款时间 {{ order.retime }}
                            <br />
                            退款说明 {{ order.retNote }}
                            <br />
                            退款人 {{ order.retName }}
                        </div>
                    </div>
                    <div>
                        <p style="border-left: 5px solid black; padding-left: 10px; margin: 10px 0">订单备注</p>
                        <div style="padding-left: 20px">
                            内部ID {{ order._id }}

                            <br />
                            用户串码 {{ order.openid }}
                            <br />
                            影院编号 {{ order.mcode }}
                            <br />
                            {{ order.Note }}
                        </div>
                    </div>
                    <!-- 商品 票券信息 -->
                    <div>
                        <p style="border-left: 5px solid black; padding-left: 10px; margin: 10px 0">商品/票券信息</p>
                        <div style="padding-left: 20px"></div>
                    </div>
                </Card>
            </div>
            <!-- 表格显示区域 -->
            <div style="margin-top: 30px" v-for="item in order.gooslist">
                <Table stripe :columns="item.title" :data="item.list"></Table>
            </div>
            <div>
                <!-- 单笔退款弹出 -->
                <Modal v-model="modal1" title="退款">
                    <p>
                        <span style="margin-right: 10px">退款备注</span>
                        <Input v-model="note_refund" style="width: 300px" placeholder="请输入退款备注"></Input>
                    </p>
                    <div slot="footer">
                        <Button @click="() => (modal1 = false)"> 取消</Button>
                        <Button type="primary" @click="() => to_refund('退款')"> 确定</Button>
                    </div>
                </Modal>
                <!-- 全额退款弹出 -->
                <Modal v-model="modal2" title="退款订单">
                    <p>
                        <span style="margin-right: 10px">退款备注</span>
                        <Input v-model="note_refund_all" style="width: 300px" placeholder="请输入退款备注"></Input>
                    </p>
                    <div slot="footer">
                        <Button @click="() => (modal2 = false)"> 取消</Button>
                        <Button type="primary" @click="() => to_refund('全部退款')"> 确定</Button>
                    </div>
                </Modal>
            </div>
        </div>
        <Card>
            <Tabs type="card" @on-click="(value) => changeTabs(value)" v-model="currentTab">
                <TabPane v-for="item in pannerConfig" :label="item" :name="item">
                    <div class="flex justify-end pb-5">
                        <Form inline>
                            <FormItem v-if="currentTab == '持有票券'">
                                <div>数量:{{ NetSalesUserInfo.total }}</div>
                            </FormItem>
                            <FormItem v-if="currentTab == '持有票券'">
                                <Select v-if="NetSalesUserInfo.openid" v-model="couponSelectValue" style="width: 200px">
                                    <Option v-for="item in couponSelectOption" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem v-if="currentTab == '持有票券'">
                                <Button
                                    @click="() => to_GetNetSalesUserInfo(NetSalesUserInfo, '持有票券', 1, couponSelectValue)"
                                    type="primary"
                                    v-if="NetSalesUserInfo.openid"
                                    >查找</Button
                                >
                            </FormItem>
                            <FormItem>
                                <Button @click="() => table_dowload_card()" v-if="NetSalesUserInfo.openid">导出数据</Button>
                            </FormItem>
                        </Form>
                    </div>
                    <Table size="small" :columns="NetSalesUserInfo.title" :data="NetSalesUserInfo.list">
                        <template slot-scope="{ row }" slot="promoCode">
                            <img v-if="row.promoCode" style="width: 50px; height: 50px" :src="row.promoCode" alt="" />
                        </template>

                        <template slot-scope="{ row, index }" slot="paytype">
                            {{ row.paytype.join(' | ') }}
                        </template>
                        <template slot-scope="{ row, index }" slot="Goodstype">
                            {{ row.Goodstype.join(' | ') }}
                        </template>
                        <template slot-scope="{ row, index }" slot="orderAbout">
                            {{ row.orderAbout.join(' ') }}
                        </template>
                        <template slot-scope="{ row, index }" slot="markInRefund">
                            <div v-if="row.markInRefund" class="text-red-500">已退</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="opreate">
                            <div class="flex">
                                <div class="mr-2" v-show="currentTab == '持有票券'">
                                    <Poptip transfer confirm title="是否解绑该票券?" @on-ok="() => deleteCoupon(row)">
                                        <Button size="small" type="error">解绑</Button>
                                    </Poptip>
                                </div>
                                <Button v-if="row._id" @click="() => View(row)" size="small">查看</Button>
                                <Dropdown v-if="row.TicketID" @on-click="(value) => dropDownGivCouponMenuSelect(row, value)" trigger="click" transfer>
                                    <Button size="small" type="primary">
                                        更多
                                        <Icon type="ios-arrow-down"></Icon>
                                    </Button>
                                    <DropdownMenu slot="list">
                                        <DropdownItem name="停用">停用</DropdownItem>
                                        <DropdownItem name="启用">启用</DropdownItem>
                                        <DropdownItem name="作废">作废</DropdownItem>
                                        <DropdownItem name="延期">延期</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <Dropdown v-show="currentTab == '绑定会员卡'" @on-click="(value) => dropDownMenuSelect(row, value)" trigger="click" transfer>
                                <Button size="small" type="primary">
                                    操作
                                    <Icon type="ios-arrow-down"></Icon>
                                </Button>
                                <DropdownMenu slot="list">
                                    <DropdownItem name="消费记录">消费记录</DropdownItem>
                                    <DropdownItem name="历史消费" v-if="row.ID != 0">历史消费</DropdownItem>
                                    <DropdownItem name="冻结" v-if="row.status != '冻结'">冻结</DropdownItem>
                                    <DropdownItem name="解冻" v-if="row.status != '正常'">解冻</DropdownItem>
                                    <DropdownItem name="解除绑定">解除绑定</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </template>
                    </Table>
                    <div style="text-align: right; padding: 20px">
                        <Page
                            @on-change="(value) => to_GetNetSalesUserInfo({ APPUSERID: NetSalesUserInfo.APPUSERID }, currentTab, value)"
                            :total="NetSalesUserInfo.total"
                            :page-size="NetSalesUserInfo.size"
                        />
                    </div>
                </TabPane>
            </Tabs>
        </Card>
        <div style="height: 50px; width: 100vw"></div>
        <Modal width="1000" v-model="ModalNetSalesUser">
            <div style="padding: 50px 0">
                <Table :data="NetSalesUserList" :columns="NetSalesUserTitle">
                    <template slot-scope="{ row }" slot="operation">
                        <Button @click="() => to_GetNetSalesUserInfo(row)">选择</Button>
                    </template>
                    <template slot-scope="{ row }" slot="nickName">
                        <div style="display: flex">
                            <img style="width: 30px; height: 30px; border-radius: 50%" :src="row.imgURl" alt="" />
                            <div>
                                {{ row.nickName }}
                            </div>
                        </div>
                    </template>
                </Table>
            </div>
            <div slot="footer"></div>
        </Modal>
        <Modal :mask-closable="false" :closable="false" v-model="modalOpreateReason" title="操作原因">
            <Form ref="formOpreate" :model="formOpreate" :rules="ruleFormOpreate">
                <FormItem prop="content">
                    <Input placeholder="请输入操作原因" type="textarea" v-model="formOpreate.content"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="() => cancel()">取消</Button>
                <Button @click="() => confirm()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal :mask-closable="false" :closable="false" v-model="modalGivCoupon" title="赠送票券">
            <Form :label-width="100" ref="formGivCoupon" :model="formGivCoupon" :rules="ruleGivCoupon" style="width: 300px">
                <FormItem label="赠送票券" prop="id">
                    <Select @on-change="changeGivCounpon" v-model="formGivCoupon.id">
                        <Option :key="index" v-for="(item, index) in givCouponConfig" :value="item.id">{{ item.name }}</Option>
                    </Select>
                    <div v-if="formGivCoupon.id" class="flex">
                        <div>库存量</div>
                        <div>{{ currentGivCounpon.generation }}</div>
                    </div>
                </FormItem>
                <FormItem label="赠送数量" prop="num">
                    <Input placeholder="请输入赠送数量" type="number" v-model="formGivCoupon.num"></Input>
                </FormItem>
                <FormItem label="有效期" prop="validity">
                    <Input placeholder="请输入有效期" type="number" v-model.number="formGivCoupon.validity"></Input>
                    <div v-if="currentGivCounpon && cpGivCouponEnd != -1" class="flex">
                        <div>有效期</div>
                        <div>{{ cpGivCouponEnd }}</div>
                    </div>
                </FormItem>
                <FormItem label="赠送备注" prop="note">
                    <Input placeholder="请输入赠送备注" type="textarea" v-model="formGivCoupon.note"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="cancelGivCoupon">取消</Button>
                <Button @click="() => handleFormGivCoupon()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal :mask-closable="false" :closable="false" v-model="modalConfirmGivCoupon" title="提示">
            <div v-if="titleGivCoupon != '延期'">是否{{ titleGivCoupon }}该票券?</div>
            <Form v-else :label-width="100" ref="formGivCoupon" :model="formGivCoupon" :rules="ruleGivCoupon">
                <div class="flex">
                    <div style="width: 100px; text-align: right; padding: 0 10px">当前票券</div>
                    <div>{{ titleGivCouponTotal.couponCode }}</div>
                </div>
                <div class="flex">
                    <div style="width: 100px; text-align: right; padding: 0 10px">原有效期</div>
                    <div>{{ titleGivCouponTotal.end }}</div>
                </div>
                <div class="flex">
                    <div style="width: 100px; text-align: right; padding: 0 10px">延期时间</div>
                    <RadioGroup v-model="buttonSize" type="button">
                        <Radio label="自定义">自定义</Radio>
                        <Radio label="一周">一周</Radio>
                        <Radio label="半月">半月</Radio>
                        <Radio label="一月">一月</Radio>
                        <Radio label="一年">一年</Radio>
                    </RadioGroup>
                </div>
                <FormItem label="延期至">
                    <DatePicker @on-change="(value) => changeGivCoupon(value)" format="yyyy-MM-dd" type="date" :value="cpGivCouponEndTime"> </DatePicker>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="modalConfirmGivCoupon = false">取消</Button>
                <Button @click="() => handleConfirmCoupon()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { download } from '@/utils/table_download.js';
import dayjs from 'dayjs';
import {
    GetOrderDatab,
    OrderRefund,
    memberQuery,
    memberOrderQuery,
    GetNetSalesUserInfo,
    QueryAppUser,
    GetAppUserInfo,
    UNCouponBind,
    EditAppuser,
    MemCardInfo,
    AppUserRCard,
    GetPresetValue,
    GiftTickets,
    SetCouponState,
} from '@/request/api.js';
export default {
    name: 'navbar',
    data() {
        return {
            buttonSize: '自定义',
            currentTab: '绑定会员卡',
            NetSalesUserInfo: '',
            // pannerConfig: ['绑定会员卡', '持有票券', '订单列表', '身份标识'],
            pannerConfig: ['绑定会员卡', '持有票券', '订单列表', '订单列表-旧版', '操作记录'],
            ModalNetSalesUser: false,
            NetSalesUserList: [],
            NetSalesUserTitle: [],
            currentNetSalesUser: '快捷查询',
            NetSalesUserConfig: ['快捷查询', '曾用号码', '绑定会员卡'],
            total: '', //查询总长度
            infos: '', //电话查询信息
            title: [], //
            list: [], //
            phone: '', //查询号码
            note_refund_all: '', //全额退款备注
            note_refund: '', //退款备注
            row_detail: {},
            modal2: false,
            modal1: false,
            state_color: {
                12: '',
                1: 'warning',
                2: 'primary',
                4: 'error',
                5: 'default',
                6: 'error',
            },
            state: {
                12: '全部', //
                1: '未支付', //黄色    <Tag color="warning">warning</Tag>
                2: '已支付', //蓝色<Tag color="primary">primary</Tag>
                4: '已退款', //红色  <Tag color="error">error</Tag>
                5: '已关闭', //灰色 <Tag color="default">default</Tag>
                6: '部分退款',
            },
            order: {}, //订单对象信息
            keyword: '', //订单号
            couponSelectValue: '未使用',
            couponSelectOption: [
                {
                    value: '全部',
                    label: '全部',
                },
                {
                    value: '未使用',
                    label: '未使用',
                },
                {
                    value: '已使用',
                    label: '已使用',
                },
                {
                    value: '已过期',
                    label: '已过期',
                },
                {
                    value: '已停用',
                    label: '已停用',
                },
            ],
            modalOpreateReason: false,

            modalGivCoupon: false,
            formGivCoupon: {
                id: '',
                num: '',
                validity: 0,
                note: '',
            },
            ruleGivCoupon: {
                id: [{ required: true, trigger: 'blur', message: '请选择赠送库存' }],
                num: [{ required: true, trigger: 'blur', message: '请输入赠送数量' }],
                validity: [
                    { required: true, type: 'number', min: 0, message: '有效期0-365之间' },
                    { required: true, type: 'number', max: 365, message: '有效期0-365之间' },
                ],
                note: [{ required: true, trigger: 'blur', message: '请输入赠送备注' }],
            },
            givCouponConfig: [], //赠送票券配置项
            currentGivCounpon: { end: null },
            modalConfirmGivCoupon: false, //持有票券谈出提示
            titleGivCoupon: '',
            titleGivCouponTotal: {}, //表格行数据
            GivCouponDate: '',
            myselfDate: '',
            formOpreate: {
                content: '',
                type: '',
            },
            ruleFormOpreate: {
                content: [
                    { required: true, trigger: 'blur', message: '请输入操作原因' },
                    { min: 5, message: '至少需要长度为5' },
                ],
            },
            tableRowData: '',
        };
    },

    computed: {
        /**
         * 延期至的 日期显示数据
         */
        cpGivCouponEndTime() {
            //如果是自定义就显示当前时间
            /**
             * if (this.buttonSize == '自定义') {
             *     //(this.GivCouponDate == this.titleGivCoupon.end
             *     return this.GivCouponDate;
             * } else {
             */
            //一周 半月 一月 一年
            //如果是非自定义就显示计算的时间
            if (this.buttonSize == '自定义') {
                return this.myselfDate;
            }
            try {
                let data = {
                    一周: 7,
                    半月: 15,
                    一月: 30,
                    一年: 365,
                };
                let day = data[this.buttonSize];
                this.titleGivCoupon.end;
                let result = dayjs(this.titleGivCouponTotal.end).add(day, 'day').format('YYYY-MM-DD');
                this.myselfDate = result;
                return result;
            } catch (e) {
                /* handle error */
                return -1;
            }
            //            }
        },
        /**
         * 有效期计算
         */
        cpGivCouponEnd() {
            if (!this.currentGivCounpon.end) {
                return -1;
            }
            //如果是0返回原始数据
            if (this.formGivCoupon.validity == 0) {
                return this.currentGivCounpon.end;
            }
            //如果是1返回当天+1
            try {
                let result = dayjs().add(this.formGivCoupon.validity, 'day').format('YYYY-MM-DD');
                return result;
            } catch (e) {
                /* handle error */
                return -1;
            }
        },
        orderAbout() {
            return function (orderAbout) {
                return orderAbout.join(' ');
            };
        },

        order_state_tag() {
            return function (index) {
                return `<Tag color="${this.state_color[index]}">${this.state[index]}</Tag>`;
            };
        },
        modelPlaceholder() {
            let arr = this.NetSalesUserConfig;
            let item = this.currentNetSalesUser;
            let index = 0;

            arr.forEach((item1, index1) => item1 == item && (index = index1));
            let list = ['账户ID/绑定号码/用户串码', '手机号码', '卡号/物理卡号/会员卡手机号码'];
            return list[index];
        },
    },
    created() {
        //获取按钮权限 orderList
        let state = this.$my_checkBtn('orderList');
        console.log(state, '=====s');
        state ? null : this.pannerConfig.splice(3, 1);
    },
    methods: {
        /**
         * 改变延期至日期选择组建
         */
        changeGivCoupon(value) {
            this.GivCouponDate = value;
            this.buttonSize = '自定义';
        },
        async handleConfirmCoupon() {
            let TicketID = this.titleGivCouponTotal.TicketID;
            let SetState = this.titleGivCoupon;
            let passdata = {
                TicketID,
                SetState,
            };
            if (SetState == '延期') {
                //表单必填UpDate
                if (this.buttonSize == '自定义') {
                    passdata['UpDate'] = this.GivCouponDate;
                } else {
                    passdata['UpDate'] = this.cpGivCouponEndTime;
                }
            }
            let res = await SetCouponState(passdata);
            if (res.errcode != 0) return;
            this.$Message.success('操作成功');
            this.modalConfirmGivCoupon = false;
            this.changeTabs(this.currentTab);
        },
        /**
         * 持有票券下拉选择框 更多选择后
         */
        dropDownGivCouponMenuSelect(row, value) {
            this.titleGivCouponTotal = row;
            this.GivCouponDate = row.end;
            this.myselfDate = row.end;
            this.titleGivCoupon = value;
            this.buttonSize = '自定义';
            this.modalConfirmGivCoupon = true;
        },
        /**
         * 取消赠送票券弹出an
         */
        cancelGivCoupon() {
            this.modalGivCoupon = false;
        },
        /**
         * 切换赠送票群
         */
        changeGivCounpon() {
            let data = this.givCouponConfig.filter((item) => item.id == this.formGivCoupon.id)[0];
            this.currentGivCounpon = data;
        },
        test() {
            console.log(1);
        },
        /**
         * 表格内的查看
         */
        View(value) {
            const { _id, orderid } = value;
            switch (this.currentTab) {
                case '订单列表-旧版':
                    this.routeTo('orderFundDetail?id=' + orderid);
                    break;

                default:
                    this.routeTo('orderDetail?id=' + _id);
            }
        },
        /**
         * 解绑会员卡
         */
        async fireMemberCardId() {
            const { CardID } = this.tableRowData;
            const { APPUSERID } = this.NetSalesUserInfo;
            let res = await AppUserRCard({
                appuserid: APPUSERID,
                cardID: CardID,
            });
            if (res.errcode != 0) return;
            this.to_GetNetSalesUser();
        },
        /**
         * 冻结/解冻操作
         */
        async frezz(state) {
            const { CardID } = this.tableRowData;
            let type = state ? '冻结' : '正常';
            let passdata = {
                type,
                CardID,
            };
            let res = await MemCardInfo(passdata);
            if (res.errcode != 0) return;
            this.modalEditData = false;
            this.$Message.success((state ? '冻结' : '解冻') + '成功');
            this.to_GetNetSalesUser();
        },
        /**
         * 操作下拉选择框选中
         */
        dropDownMenuSelect(row, value) {
            const { openid, CardID } = row;
            this.tableRowData = row;
            switch (value) {
                case '消费记录':
                    window.open('/costRecord?CardID=' + CardID);
                    break;
                case '历史消费':
                    window.open('/historyOrder?CardID=' + CardID);
                    break;
                case '冻结':
                    this.frezz(true);
                    break;
                case '解冻':
                    this.frezz(false);
                    break;
                case '解除绑定':
                    this.fireMemberCardId();
                    break;
            }
        },
        cancel() {
            this.$refs['formOpreate'].resetFields();
            this.modalOpreateReason = false;
        },
        confirm() {
            this.$refs['formOpreate'].validate(async (valid) => {
                if (valid) {
                    const { type, content } = this.formOpreate;
                    const { APPUSERID } = this.NetSalesUserInfo;
                    let res = await EditAppuser({
                        appuserid: APPUSERID,
                        type,
                        content,
                    });

                    this.modalOpreateReason = false;
                    this.$refs['formOpreate'].resetFields();
                    this.to_GetNetSalesUser({
                        querytype: '记录ID',
                        content: this.NetSalesUserInfo.APPUSERID,
                    });
                }
            });
        },
        async handleFormGivCoupon() {
            this.$refs['formGivCoupon'].validate(async (valid) => {
                if (valid) {
                    let passdata = {
                        openid: this.NetSalesUserInfo.openid,
                        id: this.formGivCoupon.id,
                        num: this.formGivCoupon.num,
                        validity: this.formGivCoupon.validity,
                        note: this.formGivCoupon.note,
                    };
                    let res = await GiftTickets(passdata);
                    if (res.errcode != 0) return;

                    this.$Message.success('生成绑定中，请稍后查询');
                    this.modalGivCoupon = false;
                }
            });
        },
        /**
         * 获取后台赠送票券配置项
         */
        async getGiftTickets() {
            let res = await GetPresetValue({ type: '后台赠送票券选择' });
            if (res.errcode != 0) return;
            this.givCouponConfig = res.data;
        },
        /**
         * 操作按钮
         */
        opreateBtn() {
            if (this.formOpreate.type == '赠送票券') {
                this.getGiftTickets();
                //弹框带有下拉 选择的
                this.modalGivCoupon = true;
                this.$refs['formGivCoupon'].resetFields();

                return;
            }
            if (this.formOpreate.type == '') return;
            this.modalOpreateReason = true;
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        /**
         * 解除绑定持有票券
         */
        async deleteCoupon(value) {
            console.log(value, '解除绑定持有票券=');
            const { openid } = this.NetSalesUserInfo;
            const { TicketID } = value;
            let passdata = { openid, TicketID };
            let res = await UNCouponBind(passdata);
            if (res.errcode != 0) return;
            this.changeTabs(this.currentTab);
        },
        async table_dowload_card(status = '全部') {
            let pass_data = {
                APPUSERID: this.NetSalesUserInfo.APPUSERID, //网售账户对应的哦陪你的
                gettype: this.currentTab, //['绑定会员卡','持有票券','订单列表','身份标识'] 四选一
                status: status,
                all: true, //是否获取全部数据
                openid: this.NetSalesUserInfo.openid,
            };
            let res = await GetAppUserInfo(pass_data);
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '网售账号',
                headers,
                data,
            });
        },
        changeTabs(value) {
            if (!this.NetSalesUserInfo.APPUSERID) return;

            this.to_GetNetSalesUserInfo(
                {
                    APPUSERID: this.NetSalesUserInfo.APPUSERID,
                },
                value
            );
        },
        async to_GetNetSalesUserInfo(row, type = '绑定会员卡', page = 1, status = '全部') {
            if (Object.keys(row).length > 1) {
                this.NetSalesUserInfo = row;
            }
            console.log(row);
            if (this.currentTab == '持有票券') {
                status = this.couponSelectValue;
            }
            let pass_data = {
                APPUSERID: row.APPUSERID,
                gettype: type, //['绑定会员卡','持有票券','订单列表','身份标识'] 四选一
                page: page, //当前页码
                status: status,
                all: false, //是否获取全部数据
                openid: this.NetSalesUserInfo.openid,
            };
            let res = await GetAppUserInfo(pass_data);
            if (res.errcode != 0) return;
            if (type != '操作记录') {
                res.data.title.push({
                    title: '操作',
                    key: 'opreate',
                    slot: 'opreate',
                });
            }

            res.data.title.forEach((item, index) => {
                if (item.key == 'promoCode') {
                    item.slot = 'promoCode';
                }
                if (item.key == 'paytype') {
                    item['slot'] = 'paytype';
                }
                if (item.key == 'Goodstype') {
                    item['slot'] = 'Goodstype';
                }
                if (item.key == 'orderAbout') {
                    item['slot'] = 'orderAbout';
                }
                if (item.key == 'markInRefund') {
                    item['slot'] = 'markInRefund';
                }
            });
            // if(this.currentTab=="持有票券"){
            // 	let unUseList = res.data.list.filter((item,index)=>item.status=='未使用')
            // 	// 当存在未使用的票券的时候
            // 	if(unUseList.length>0){
            // 		res.data.title.push({
            // 			title:"操作",
            // 			slot:"opreate",
            // 			key:"opreate"
            // 		})
            // 	}
            // }
            this.NetSalesUserInfo = JSON.parse(JSON.stringify(Object.assign(this.NetSalesUserInfo, res.data)));
            this.ModalNetSalesUser = false;
        },
        async to_GetNetSalesUser(a = null) {
            if (!this.phone && !a) return;
            let res = await QueryAppUser({
                querytype: a ? a.querytype : this.currentNetSalesUser,
                content: a ? a.content : this.phone,
            });
            if (res.errcode != 0) return;
            this.phone = '';
            // 直接发包
            if (res.data.list.length == 1) {
                this.currentTab = '绑定会员卡';
                this.NetSalesUserInfo = res.data.list[0];
                this.to_GetNetSalesUserInfo({
                    APPUSERID: res.data.list[0].APPUSERID,
                });
                return;
            }
            res.data.title.push({
                key: 'operation',
                title: '操作',
                slot: 'operation',
            });
            res.data.title.forEach((item) => {
                if (item.key == 'nickName') {
                    item.slot = 'nickName';
                }
            });

            this.ModalNetSalesUser = true;
            this.NetSalesUserList = res.data.list;
            this.NetSalesUserTitle = res.data.title;
        },

        async table_dowload() {
            let pass_data = {
                openid: this.infos.openid,
                page: 1,
                all: 1,
            };

            let { data: res_data } = await memberOrderQuery(pass_data);

            let headers = res_data.title;
            let data = res_data.list.map((item) => {
                return {
                    orderid: item.orderid,
                    orderName: item.orderName,
                    createTime: item.createTime,
                    state: item.state,
                    pay: item.pay,
                    cash_fee: item.cash_fee,
                };
            });
            download({
                worksheet: this.infos.phone + '',
                headers,
                data,
            });
        },

        async to_memberQuery(page = 1) {
            let pass_data = {
                phone: this.phone,
            };

            let { data: res_data } = await memberQuery(pass_data);

            this.infos = res_data[0];
            let pass_data1 = {
                openid: res_data[0]['openid'],
                page: page,
            };
            let { data: res_data1 } = await memberOrderQuery(pass_data1);
            this.total = res_data1.total;
            res_data1.title.push({
                key: 'operation',
                title: '操作',
                slot: 'operation',
            });
            this.title = res_data1.title;
            this.list = res_data1.list;
        },

        refund_single(row) {
            this.modal1 = true;
            this.row_detail = row;
        },

        async to_refund(type) {
            if (type == '全部退款') {
                if (!this.note_refund_all) {
                    this.$mytoast_err('请输入备注信息');
                    return;
                }
                let pass_data = {
                    retName: JSON.parse(localStorage.getItem('选中品牌1')).user.name,
                    orderid: this.order.orderid, //退款订单号
                    all: true, //全部退款 优先级高于uidList
                    uidList: [], //退款订单内某个商品如果传了all=true 此部分退款无效
                    retNote: this.note_refund_all, //退款备注
                };

                let res_data = await OrderRefund(pass_data);

                if (res_data.code != 200) {
                    this.$mytoast(res_data.msg);
                    return;
                }
                if (res_data.msg == 'ok') {
                    this.$mytoast('退款成功');
                }
                this.keyword = res_data.data.orderid;
                this.to_GetOrderDatab();
                this.modal2 = false;
            }
            if (type == '退款') {
                if (!this.note_refund) {
                    this.$mytoast_err('请输入备注信息');
                    return;
                }

                let pass_data = {
                    retName: JSON.parse(localStorage.getItem('选中品牌1')).user.name,
                    orderid: this.order.orderid, //退款订单号
                    all: false, //全部退款 优先级高于uidList
                    uidList: [this.row_detail], //退款订单内某个商品如果传了all=true 此部分退款无效
                    retNote: this.note_refund, //退款备注
                };
                let res_data = await OrderRefund(pass_data);

                if (res_data.code != 200) {
                    this.$mytoast(res_data.msg);
                    return;
                }
                if (res_data.msg == 'ok') {
                    this.$mytoast('退款成功');
                }
                this.keyword = res_data.data.orderid;
                this.to_GetOrderDatab();
                this.modal1 = false;
            }
        },

        async to_GetOrderDatab() {
            if (!this.keyword) {
                this.$mytoast_err('请输入订单号查询');
                return;
            }
            let pass_data = {
                keyword: this.keyword,
            };

            let res_data = await GetOrderDatab(pass_data);

            if (res_data.code == 200) {
                let center = JSON.parse(JSON.stringify(res_data.data));
                console.log(center);
                center.gooslist.forEach((item, index) => {
                    center.gooslist[index].title.push({
                        key: 'operation',
                        title: '操作',
                        slot: 'operation',
                    });
                });
                this.order = center;
            } else {
                this.$mytoast_err(res_data.msg);
            }
        },
    },
};
</script>
