import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import paiqi from '@/views/content/functions/paiqi.vue'


import UserManagement from '@/views/content/UserManagement.vue'
import RoleManagement from '@/views/content/RoleManagement.vue'
import RuleManagement from '@/views/content/RuleManagement.vue'

import filmPrice from '@/views/content/functions/filmPrice.vue'
import memberPrepaidPhoneButton from '@/views/content/functions/memberPrepaidPhoneButton.vue'
import offlinePaymentOrders from '@/views/content/functions/offlinePaymentOrders.vue'
import order from '@/views/content/functions/order.vue'
import sellProduct from '@/views/content/functions/sellProduct.vue'
import ticketQuery from '@/views/content/functions/ticketQuery.vue'
import update from '@/views/content/functions/update.vue'
import db from '@/views/content/functions/db.vue'
import select_stores from '@/views/content/functions/select_stores.vue'
import refund from '@/views/content/functions/refund.vue'
import store_settings from '@/views/content/functions/store_settings.vue'
import brand_management from '@/views/content/functions/brand_management.vue'
import store_management from '@/views/content/functions/store_management.vue'
import goods_detail from '@/views/content/functions/goods_detail.vue'
import goods_type from '@/views/content/functions/goods_type.vue'
import schedule_the_ticket_sales from '@/views/content/functions/schedule_the_ticket_sales.vue'
import new_data_summary from '@/views/content/functions/new_data_summary.vue'
import ticket_order from '@/views/content/functions/ticket_order.vue'
import Tickettoapplyfor from '@/views/content/functions/Tickettoapplyfor.vue'
import ticket_query from '@/views/content/functions/ticket_query.vue'
import ting_management from '@/views/content/functions/ting_management.vue'
import pricing_strategy from '@/views/content/functions/pricing_strategy.vue'
import goods_sales_subsidiary from '@/views/content/functions/goods_sales_subsidiary.vue'
import member_the_query from '@/views/content/functions/member_the_query.vue'
import equipment_management from '@/views/content/functions/equipment_management.vue'
import membership_card_queries from '@/views/content/functions/membership_card_queries.vue'
import configuration_management from '@/views/content/configuration_management.vue'
import distributors from '@/views/content/functions/distributors'
import stay_single_fill_in from '@/views/content/functions/stay_single_fill_in'
import activity_codes from '@/views/content/functions/activity_codes'
import account from '@/views/content/functions/account'
import paytype from '@/views/content/functions/paytype'
import charged from '@/views/content/functions/charged'
import yuanGonQrcode from '@/views/content/functions/yuanGonQrcode'
import Inventorymanagement from '@/views/content/functions/Inventorymanagement'
import Groupmanagement from '@/views/content/functions/Groupmanagement'
import Sellproductmanagement from '@/views/content/functions/Sellproductmanagement'
import membership_card_queriesv1 from '@/views/content/functions/membership_card_queriesv1'
import member_the_queryv1 from '@/views/content/functions/member_the_queryv1'
import pricing_strategy_v2 from '@/views/content/functions/pricing_strategy_v2'
import SellproductmanagementV3 from '@/views/content/functions/SellproductmanagementV3'
import GroupmanagementV3 from '@/views/content/functions/GroupmanagementV3'
import ting_managementV2 from '@/views/content/functions/ting_managementV2'
import couponRule from '@/views/content/functions/couponRule'

import member_the_query_v3 from '@/views/content/functions/member_the_query_v3'
import membership_card_queries_v3 from '@/views/content/functions/membership_card_queries_v3'
import historyOrder from '@/views/content/functions/historyOrder'
import memberLevel from '@/views/content/functions/memberLevel'
import filmPriceV2 from '@/views/content/functions/filmPriceV2'
import endPointMgr from '@/views/content/functions/endPointMgr'
import popNotice from '@/views/content/functions/popNotice'
import suggestion from '@/views/content/functions/suggestion'
import orderSelect from '@/views/content/functions/orderSelect'
import orderDetail from '@/views/content/functions/orderDetail'
import refundV3 from '@/views/content/functions/refundV3'
import offlinePaymentOrdersV2 from '@/views/content/functions/offlinePaymentOrdersV2'
import allInTable from '@/views/content/functions/allInTable'
import costRecord from '@/views/content/functions/costRecord'
import couponSeach from '@/views/content/functions/couponSeach'
import activity_codeV3 from '@/views/content/functions/activity_codeV3'
import dyShopping from "@/views/content/functions/dyShopping"
import payCodeDetail from "@/views/content/functions/payCodeDetail"
import postBorn from "@/views/content/functions/postBorn"
import userNotice from "@/views/content/functions/userNotice"
import orderFundDetail from "../../src/views/content/functions/orderFundDetail.vue"
import Opendata from '../views/content/functions/opendata.vue'
import systemCoupon from '../views/content/functions/systemCoupon.vue'



import nofound from "../components/error-page/404.vue"



import ticket_setting from '@/views/content/ticket_setting.vue'
import {
	GetButMandate
} from '../request/api.js'

import test_csv_parse from "../views/dev/test_csv_parse"
import test_capture from "../views/dev/test_capture"
import ajax_config  from '../../url.json';


Vue.use(VueRouter)

const routes = [
	{
		path: '/404',
		component: nofound
	},
	{
		path: '*',
		redirect: '/404'
	},
	{
		path: '/stay_single_fill_in',
		component: stay_single_fill_in,
	},
	{
		path: '/',
		redirect: '/login'
	},

	{
		path: '/home',
		component: Home,
		children: [,
			{
				path: '/pricing_strategy_v2',
				component: pricing_strategy_v2
			},
			{
				path: '/member_the_queryv1',
				component: member_the_queryv1
			},
			{
				path: '/membership_card_queriesv1',
				component: membership_card_queriesv1
			},
			{
				path: '/Sellproductmanagement',
				component: Sellproductmanagement
			},
			{
				path: '/Groupmanagement',
				component: Groupmanagement
			},
			{
				path: '/Inventorymanagement',
				component: Inventorymanagement
			},
			{
				path: '/yuanGonQrcode',
				component: yuanGonQrcode
			},
			{
				path: '/charged',
				component: charged
			},
			{
				path: '/paytype',
				component: paytype
			},
			{
				path: '/account',
				component: account
			},
			{
				path: '/paiqi',
				component: paiqi
			}, {
				path: '/activity_codes',
				component: activity_codes,
			}, {
				path: '/distributors',
				component: distributors,
			}, {
				path: '/equipment_management',
				component: equipment_management,
			}, {
				path: '/configuration_management',
				component: configuration_management,
			}, {
				path: '/ticket_setting',
				component: ticket_setting,
			}, {
				path: '/membership_card_queries',
				component: membership_card_queries,
			}, {
				path: '/member_the_query',
				component: member_the_query,
			}, {
				path: '/goods_sales_subsidiary',
				component: goods_sales_subsidiary,
			}, {
				path: '/pricing_strategy',
				component: pricing_strategy,
			}, {
				path: '/ting_management',
				component: ting_management,
			}, {
				path: '/ticket_query',
				component: ticket_query,
			}, {
				path: '/ticket_order',
				component: ticket_order,
			}, {
				path: '/Tickettoapplyfor',
				component: Tickettoapplyfor,
			}, {
				path: '/schedule_the_ticket_sales',
				component: schedule_the_ticket_sales,
			}, {
				path: '/new_data_summary',
				component: new_data_summary,
			}, {
				path: '/goods_type',
				component: goods_type,
			}, {
				path: '/goods_detail',
				component: goods_detail,
			}, {
				path: '/user/management',
				component: UserManagement,
			}, {
				path: '/role/management',
				component: RoleManagement,
			}, {
				path: '/rule/management',
				component: RuleManagement,
			}, {
				path: '/refund',
				component: refund,
			}, {
				path: '/filmPrice',
				component: filmPrice,
			}, , {
				path: '/memberPrepaidPhoneButton',
				component: memberPrepaidPhoneButton,
			}, , {
				path: '/offlinePaymentOrders',
				component: offlinePaymentOrders,
			}, , {
				path: '/order',
				component: order,
			}, , {
				path: '/sellProduct',
				component: sellProduct,
			}, , {
				path: '/ticketQuery',
				component: ticketQuery,
			}, {
				path: '/update',
				component: update,
			}, {
				path: '/db',
				component: db,
			},
			{
				path: '/store_settings',
				component: store_settings
			},
			{
				path: '/brand_management',
				component: brand_management
			},
			{
				path: '/store_management',
				component: store_management
			},
			{
				path: '/SellproductmanagementV3',
				component: SellproductmanagementV3
			},
			{
				path: '/GroupmanagementV3',
				component: GroupmanagementV3
			},
			{
				path: '/ting_managementV2',
				component: ting_managementV2
			},
			{
				path: "/couponRule",
				component: couponRule
			},
			{
				path: "/member_the_query_v3",
				component: member_the_query_v3
			},
			{
				path: "/membership_card_queries_v3",
				component: membership_card_queries_v3
			},
			{
				path: "/memberLevel",
				component: memberLevel
			},
			{
				path: "/filmPriceV2",
				component: filmPriceV2
			},
			{
				path: "/endPointMgr",
				component: endPointMgr
			},
			{
				path: "/popNotice",
				component: popNotice
			},
			{
				path: "/suggestion",
				component: suggestion
			},
			{
				path: "/orderSelect",
				component: orderSelect
			},
			{
				path: "/refundV3",
				component: refundV3
			},
			{
				path: "/offlinePaymentOrdersV2",
				component: offlinePaymentOrdersV2
			},
			{
				path: "/allInTable",
				component: allInTable
			},
			{
				path: "/couponSeach",
				component: couponSeach
			},
			{
				path: "/activity_codeV3",
				component: activity_codeV3
			},
			{
				path: "/dyShopping",
				component: dyShopping
			},
			{
				path: "/postBorn",
				component: postBorn
			},
			{
				path:"/userNotice",
				component:userNotice
			},
            {
                path:"/opendata",
                component:Opendata
            },
                        {
                path:"/systemCoupon",
                component:systemCoupon
            }


		]
	},
	{
		path: '/login',
		component: Login
	},
	{
		path: '/select_stores',
		component: select_stores
	},
	{
		path: '/historyOrder',
		component: historyOrder
	},
	{
		path: "/orderDetail",
		component: orderDetail
	},
	{
		path: "/costRecord",
		component: costRecord
	},
	{
		path: "/payCodeDetail",
		component: payCodeDetail
	},
	{
		path: "/test_csv_parse",
		component: test_csv_parse
	},
	{
		path: "/test_capture",
		component: test_capture
	},
    {
        path:"/orderFundDetail",
        component:orderFundDetail 
    }
]

const router = new VueRouter({
	mode: 'history',
	base:ajax_config.base?ajax_config.base:undefined,
	routes
})

router.beforeEach(async (to, from, next) => {
	console.log(to, from);
	try {
		await Auth(to)
	} catch (error) {
		Vue.prototype.$Message
			.error(error.message || error)
		setTimeout(() => {
			window.location.replace('/')
		}, 1500);
	}

	next();
})
/**
 * 按钮权建设计
 */
async function Auth(to) {
	let notUrl = [
		"/payCodeDetail",
		"/historyOrder",
		"/costRecord",
		"/orderDetail",
		"/404",
		'/login',//登录页
		'/home',//首页
		'user/management',//品牌管理页面
		'/select_stores',//选择门店页面
		'/stay_single_fill_in',//填入门店页面
        '/orderFundDetail'
	]
	//取出侧边导航列表
	let nav = JSON.parse(localStorage.getItem('选中品牌1'))
	if (nav && notUrl.indexOf(to.path) == -1) {
		checkInRoute(nav,to.path)
		checkLocal()
		// 对侧边导航
		nav = nav.nat;
		let pageuid = 123456;
		nav.forEach(item => {
			item.child.forEach(itemin => {

				if (to.path == '/' + itemin.name) {
					pageuid = itemin.uid
				}
			})
		})
		let type = JSON.parse(localStorage.getItem('type'))
		console.log(type,'===')
		if (type == 2) {
			let pass_data = {
				'mcode': localStorage.getItem('mcode'),
				'pageuid': pageuid
			}

			// 发送权限请求
			let res_data = await GetButMandate(pass_data)
			if (res_data.code == 200) {
				localStorage.setItem('current_page_button', JSON.stringify(res_data.data))

			}
		}
	}
}
/**
 * 判断当前跳转的页面是否在路由列表里面
 */
function checkInRoute(value,toPath){
	let arr = [];
	let str = JSON.stringify(value);
	str.replace(/\"name\"\:\"(\w+[a-zA-Z0-9\/]+)\"/gm,(a,b)=>{
	    arr.push(b)
	})
	//使用路由判断当前跳转的页面是否在路由允许里面 在 返回true 不在 返回false
	if(arr.indexOf(toPath.split('/').filter(item=>item).join('/'))==-1){
		// 不存在跳登录页
		window.location.replace("/404")
	}
}
/**
 * 判断当前缓存中是否缺少必要的数据
 */
function checkLocal() {
	//提出当前的缓存所有key
	let localArr = Object.keys(localStorage)
	//命中当前的所有必须key , "选中门店"
	let arr = ["username", "cms_token", "选中品牌1", "mcode", "type"]
	for (let i = 0; i < arr.length; i++) {
		let index = localArr.indexOf(arr[i])
		if (index == -1) {
			//当前没有该配置
			throw "登录失效，请重新登录"
		}
	}
}

export default router
