<template>
	<div>
		<div style="padding: 10px;text-align: right;">
			<Button @click="()=>toBeSome()" type="primary">同步信息</Button>
		</div>
		<Table :columns="tableData.title" :data="tableData.list">
			<template slot-scope="{row,index}" slot="No">
				 <i-Switch v-model="tableData.list[index].No" @on-change="(value)=>switchChange(value,row)" />
			</template>
		</Table>
	</div>
</template>

<script>
	import {
		SeatInfoUpDate
	} from "@/request/api"
	export default {
		name: 'navbar',
		data() {
			return {
				tableData:""
			}
		},
		mounted() {
			this.fetchData("获取列表")
		},
		methods: {
			async toBeSome(){
				let res = await this.fetchData('同步信息')
				if(res.errcode!=0) return
				this.$Message.success("同步成功")
				this.fetchData("获取列表")
			},
			async switchChange(value,row){
				const { screenCode } = row
				let type = value?"禁止使用":"允许使用"
				await this.fetchData(type,screenCode)
				this.fetchData("获取列表")
			},
			async fetchData(type,screenCode){
				let passdata = {"type": type}
				if(type=="禁止使用"||type=="允许使用"){
					passdata["screenCode"] = screenCode
				}
				let res = await SeatInfoUpDate(passdata)
				if(res.errcode!=0) return
				res.data.title.forEach((item,index)=>{
					if(item.key=='No'){
						item["slot"] = "No"
					}
				})
				this.tableData = res.data
			},
		}
	}
</script>
