<template>
    <div>
        <div class="flex justify-end">
            <Form inline ref="inlineForm" :model="inlineForm" :rules="inlineRules" @submit.native.prevent="search()">
                <FormItem prop="platform">
                    <Select v-model="inlineForm.platform">
                        <Option v-for="(item, index) in configThreePlat.platform" :key="index" :value="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem prop="type">
                    <Select @on-change="changeType" :disabled="!inlineForm.platform" v-model="inlineForm.type">
                        <Option v-for="(item, index) in cpQueryData" :key="index" :value="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem prop="queryKey">
                    <Input v-model="inlineForm.queryKey" :placeholder="cpQueryPlaceHolder" />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="search()">查询</Button>
                </FormItem>
            </Form>
        </div>
        <div class="mt-2">
            <Card v-if="inlineForm.type == '会员信息' && queryData.nickname">
                <div class="flex item-center">
                    <div class="mr-10">
                        <img :src="queryData.headimgurl" class="w-20" />
                    </div>
                    <div>
                        <div class="flex">
                            <div class="flex w-48">
                                <div>会员名称:</div>
                                <div>{{ queryData.nickname }}</div>
                            </div>
                            <div class="flex">
                                <div>会员余额:</div>
                                <div>{{ queryData.membasic }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex w-48">
                                <div>会员电话:</div>
                                <div>{{ queryData.Phone }}</div>
                            </div>
                            <div class="flex">
                                <div>赠送余额:</div>
                                <div>{{ queryData.memgiv }}</div>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex w-72">
                                <div>会员串码:</div>
                                <div>{{ queryData.userid }}</div>
                            </div>
                            <div class="flex">
                                <div>注册日期:</div>
                                <div>{{ queryData.createTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end mb-2">
                    <Button @click="downloadTable">导出订单</Button>
                </div>
                <Table :columns="tableData.title" :data="tableData.list"></Table>
                <div class="flex justify-end mt-2">
                    <Page @on-change="search" :page-size="tableData.size" :total="tableData.total"></Page>
                </div>
            </Card>
            <Card v-if="inlineForm.type == '订单详情' && queryData.orderid">
                <div class="flex item-center">
                    <div class="flex-1">
                        <div class="flex">
                            <div>订单号:</div>
                            <div>{{ queryData.orderid }}</div>
                        </div>
                        <div class="flex">
                            <div>订单状态:</div>
                            <div>{{ queryData.state }}</div>
                        </div>
                        <div>
                            <div v-for="(item, index) in queryData.orderName" :key="index">{{ item }}</div>
                        </div>

                        <div class="flex">
                            <div class="flex w-48">
                                <div>支付金额:</div>
                                <div>{{ queryData.cash_fee }}</div>
                            </div>
                            <div class="flex">
                                <div>支付类型:</div>
                                <div>{{ queryData.paytype }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex w-48">
                                <div>交易时间:</div>
                                <div>{{ queryData.createTime }}</div>
                            </div>
                            <div class="flex">
                                <div>退款时间:</div>
                                <div>{{ queryData.memgiv }}</div>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex w-72">
                                <div>会员手机号:</div>
                                <div>{{ queryData.Phone }}</div>
                            </div>
                            <div class="flex">
                                <div>注册日期:</div>
                                <div>{{ queryData.createTime }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div>商品明细:</div>
                            <div>({{ queryData.storeName }})</div>
                        </div>
                    </div>
                    <div class="mr-48">
                        <img :src="queryData.imgurl" class="w-20" />
                    </div>
                </div>
                <div v-if="queryData.state != '已退款'" class="flex justify-end mb-2">
                    <Button @click="showModal" type="error">订单退款</Button>
                </div>
                <Table :columns="queryData.goodListTitle" :data="queryData.goodList"></Table>
            </Card>
            <Card v-if="inlineForm.type == '票券详情' && Object.keys(queryData).length > 0">
                <div class="flex item-center">
                    <div class="flex-1">
                        <div class="flex">
                            <div class="flex w-96">
                                <div>券码:</div>
                                <div>{{ queryData.couponCode }}</div>
                            </div>
                            <Tag v-if="queryData.status == '已核销'" color="error">已使用</Tag>
                            <Tag v-if="queryData.status == '未使用'" color="success">未使用</Tag>
                            <Tag v-if="queryData.status == '已过期'">已过期</Tag>
                        </div>
                        <div>
                            <div class="flex">
                                <div class="flex w-72">
                                    <div>批次号:</div>
                                    <div>{{ queryData.apply }}</div>
                                </div>
                                <div class="flex">
                                    <div>有效期:</div>
                                    <div>{{ queryData.satrt }}至{{ queryData.end }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex">
                            <div>票券名称:</div>
                            <div>{{ queryData.asname }}</div>
                        </div>
                        <div class="flex">
                            <div class="flex w-72">
                                <div>持有用户:</div>
                                <div>{{ queryData.Phone }}</div>
                            </div>
                            <div class="flex">
                                <div>绑定时间:</div>
                                <div>{{ queryData.toGetTime }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex">
                                <div>使用订单:</div>
                                <div>{{ queryData.orderid }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
        <Modal v-model="show" :closable="false" :mask-closable="false">
            <div>
                <h3>退款原因</h3>
                <Input v-model="note" class="w-96" />
            </div>
            <div slot="footer">
                <Button @click="show = false">取消</Button>
                <Button @click="orderRefund" type="primary">确认</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetPresetValue, ThirdPartyOpenQuery, ThirdPartyOpenOrderRefund } from '../../../request/api';

import { download } from '@/utils/table_download.js';
export default {
    name: 'opendata',
    data() {
        return {
            note: '',
            show: false,
            configThreePlat: {},
            inlineForm: {
                platform: '',
                type: '',
                queryKey: '',
                page: 1,
                all: false,
            },
            inlineRules: {
                platform: { required: true, trigger: 'blur', message: ' ' },
                type: { required: true, trigger: 'blur', message: ' ' },
                queryKey: { required: true, trigger: 'blur', message: ' ' },
            },
            queryData: {},
            tableData: {
                total: 0,
                page: 1,
                size: 1,
                title: [],
                list: [],
            },
        };
    },
    computed: {
        /**
         * 查询数据的配置项
         */
        cpQueryData() {
            if (this.inlineForm.platform) {
                let whichKey = this.inlineForm.platform;
                let data = this.configThreePlat[whichKey];
                return data;
            }
            return [];
        },
        /**
         * 查询条件提示
         */
        cpQueryPlaceHolder() {
            switch (this.inlineForm.type) {
                case '会员信息':
                    return '会员手机号';
                case '订单详情':
                    return '订单号/取票码';
                case '票券详情':
                    return '劵码';
                default:
                    return '';
            }
        },
    },
    mounted() {
        this.handleGetPresetValue();
    },
    methods: {
        showModal() {
            this.note = '';
            this.show = true;
        },
        /**
         * 订单退款
         */
        async orderRefund() {
            if (!this.note) {
                Message.warning('备注不能为空');
                return;
            }
            let passdata = {
                platform: this.inlineForm.platform,
                orderid: this.queryData.orderid,
                note: this.note,
            };
            let res = await ThirdPartyOpenOrderRefund(passdata);
            if (res.errcode != 0) return;
            this.show = false;
            this.search();
        },
        changeType(value) {
            this.queryData = {};
            this.inlineForm.queryKey = '';
        },
        /**
         * 下载表格
         */
        async downloadTable() {
            let passdata = Object.assign({}, this.inlineForm);
            passdata['queryKey'] = this.queryData.userid;
            passdata['type'] = '用户订单';
            passdata['all'] = true;
            let res = await ThirdPartyOpenQuery(passdata);
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list.map((item) => {
                return { ...item, orderAbout: item.orderAbout.join('、') };
            });
            download({
                worksheet: '订单',
                headers,
                data,
            });
        },
        /**
         * 获取三方平台预设值
         */
        async handleGetPresetValue() {
            let res = await GetPresetValue({ type: '三方平台' });
            if (res.errcode != 0) return;
            this.configThreePlat = res.data;
        },
        async search(page = 1) {
            let passdata = Object.assign({}, this.inlineForm);
            passdata['page'] = page;
            let res = await ThirdPartyOpenQuery(passdata);
            if (res.errcode != 0) return;
            this.queryData = res.data;
            if (this.inlineForm.type == '会员信息') {
                this.fetchTableData();
            }
        },
        /**
         * 获取表格数据
         */
        async fetchTableData() {
            let passdata = Object.assign({}, this.inlineForm);
            passdata['queryKey'] = this.queryData.userid;
            passdata['type'] = '用户订单';
            let res = await ThirdPartyOpenQuery(passdata);
            if (res.errcode != 0) return;
            this.tableData = res.data;
        },
    },
};
</script>
